<template>
  <div class="card p-1 rounded-lg">
    <div class="p-1">
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 text-hede">
          กำไรสุทธิวันนี้
        </p>

        <i class="far fa-ellipsis-v" />
      </div>

      <b-row class="my-1">
        <b-col md="6">
          <b-overlay :show="todayLoading">
            <div class="card-profit mb-1">
              <div class="d-flex justify-content-between align-items-center">
                <small>ยอดเงิน (THB)</small>

                <p class="m-0" :class="[todayProfitPercent > 0 ? 'text-success' : 'text-danger']">
                  ({{ todayProfitPercent.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  }}%)
                </p>
              </div>
              <h2 :class="[todayProfit > 0 ? 'text-success' : 'text-danger']">{{ todayProfit.toLocaleString("en-US", {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          }) }} <span style="font-size: 0.95rem">THB</span>
              </h2>
            </div>
          </b-overlay>

          <b-overlay :show="profitLoading">
            <div class="card-profit">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">
                  กำไรสุทธิ
                </h5>

                <small>ยอดรวมทั้งหมด</small>
              </div>

              <h2 class="mb-0 text-success">
                {{ profit.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} <span
                  style="font-size: 0.95rem">THB</span>
              </h2>

              <hr class="my-1">

              <div class="mb-50">
                <small>ยอดฝาก</small>
                <h4 class="mb-0 text-success">
                  {{ depSum.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>

              <div class="mb-50">
                <small>ยอดถอน</small>
                <h4 class="mb-0 text-danger">
                  {{ witSum.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>

              <div class="mb-50">
                <small>โบนัส</small>
                <h4 class="mb-0 text-info">
                  {{ bonus.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>

              <div class="mb-50">
                <small>เกม</small>
                <h4 class="mb-0 text-warning">
                  {{ bonusKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>
            </div>
          </b-overlay>
        </b-col>

        <b-col md="6">
          <b-overlay :show="todayLoadingKR">
            <div class="card-profit mb-1">
              <div class="d-flex justify-content-between align-items-center">
                <small>ยอดเงิน (KRW)</small>

                <p class="m-0" :class="[todayProfitPercentKR > 0 ? 'text-success' : 'text-danger']">
                  ({{ todayProfitPercentKR.toLocaleString("en-US", {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          })
                  }}%)
                </p>
              </div>
              <h2 :class="[todayProfitKR > 0 ? 'text-success' : 'text-danger']">
                {{ todayProfitKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                <span style="font-size: 0.95rem">KRW</span>
              </h2>
            </div>
          </b-overlay>

          <b-overlay :show="profitLoadingKR">

            <div class="card-profit">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">
                  กำไรสุทธิ
                </h5>

                <small>ยอดรวมทั้งหมด</small>
              </div>

              <h2 class="mb-0 text-success">
                {{ profitKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} <span
                  style="font-size: 0.95rem">KRW</span>
              </h2>

              <hr class="my-1">

              <div class="mb-50">
                <small>ยอดฝาก</small>
                <h4 class="mb-0 text-success">
                  {{ depSumKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>

              <div class="mb-50">
                <small>ยอดถอน</small>
                <h4 class="mb-0 text-danger">
                  {{ witSumKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>

              <div class="mb-50">
                <small>โบนัส</small>
                <h4 class="mb-0 text-info">
                  {{ bonusKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>

              <div class="mb-50">
                <small>เกม</small>
                <h4 class="mb-0 text-warning">
                  {{ gameKR.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </h4>
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


<script>
import {
  BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner, BRow, BCol, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      todayProfit: 0,
      todayProfitKR: 0,
      profit: 0,
      profitKR: 0,
      game: 0,
      gameKR: 0,
      bonus: 0,
      bonusKR: 0,
      todayProfitPercent: 0,
      todayProfitPercentKR: 0,
      depSum: 0,
      depSumKR: 0,
      witSum: 0,
      witSumKR: 0,
      data: null,
      todayLoading: false,
      todayLoadingKR: false,
      profitLoading: false,
      profitLoadingKR: false
    }
  },
  mounted() {
    this.getTodayPofit();
    this.getTodayPofitKR();
    this.getPofit();
    this.getPofitKR();
  },
  methods: {
    async getTodayPofit() {
      this.todayLoading = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=TODAY_PROFIT`);
        this.todayProfit = data.todayProfit;
        this.todayProfitPercent = data.todayProfitPercent;
      } catch (error) {
        console.log(error);
      } finally {

        this.todayLoading = false;
      }
    },
    async getTodayPofitKR() {
      this.todayLoadingKR = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=TODAY_PROFIT_KR`);
        this.todayProfitKR = data.todayProfitKR;
        this.todayProfitPercentKR = data.todayProfitPercentKR;
      } catch (error) {
        console.log(error);
      } finally {

        this.todayLoadingKR = false;
      }
    },
    async getPofit() {
      this.profitLoading = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=PROFIT`);
        this.profit = data.profit;
        this.game = data.game;
        this.bonus = data.bonus;
        this.depSum = data.depSum;
        this.witSum = data.witSum;
      } catch (error) {
        console.log(error);
      } finally {

        this.profitLoading = false;
      }
    },
    async getPofitKR() {
      this.profitLoadingKR = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=PROFIT_KR`);
        this.profitKR = data.profitKR;
        this.gameKR = data.gameKR;
        this.bonusKR = data.bonusKR;
        this.depSumKR = data.depSumKR;
        this.witSumKR = data.witSumKR;
      } catch (error) {
        console.log(error);
      } finally {

        this.profitLoadingKR = false;
      }
    }
  }
}
</script>